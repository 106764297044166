import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  styled,
  Divider,
  Collapse,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Custom styled Accordion for hover and click effects
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "#292929",
  color: "#fff",
  borderRadius: "25px",
  marginBottom: theme.spacing(1),
  "&:hover": {
    backgroundColor: "#444", // Background change on hover
  },
  "&.Mui-expanded": {
    backgroundColor: "darkOrange", // Background color when expanded
  },
}));

const FAQSection = () => {
  const [expanded, setExpanded] = useState(false);

  // Handler to manage accordion open and close
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ mx: "auto", pt: 4 }}>
      <Box sx={{ pb: 4 }}>
        <Typography
          variant="h4"
          sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}
        >
          Common <span style={{ color: "#ff6347" }}>Asked</span>
        </Typography>
        <Container maxWidth="xs">
          <Box
            sx={{
              height: "100%",
              borderRadius: "50%",
              border: "solid 1px #fff",
            }}
          />
        </Container>
      </Box>
      {/* FAQ Items */}
      {[
        "Speed of design delivery?",
        "What’s process like?",
        "How to request a design?",
        "In which program do you create designs?",
        "What if I don’t like the design?",
        "Are there any refunds?",
      ].map((question, index) => (
        <Box key={index}>
          <StyledAccordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              sx={{}}
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: expanded === `panel${index}` ? "#000" : "#ededed",
                    borderRadius: "20px",
                    border: "solid 1px darkOrange",
                  }}
                />
              }
            >
              <Typography
                sx={{
                  fontSize: { md: "25px", xs: "20px" },
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  color: expanded === `panel${index}` ? "#000" : "#fff", // Active question color
                  py: 1,
                }}
              >
                {question}
              </Typography>
            </AccordionSummary>
            <Collapse
              in={expanded === `panel${index}`}
              timeout={700}
              unmountOnExit
            >
              <AccordionDetails>
                <Typography
                  sx={{
                    fontSize: "17px",
                    color: "#000",
                    fontWeight: 600,
                    fontFamily: "sans-serif",
                  }}
                ></Typography>
              </AccordionDetails>
            </Collapse>
          </StyledAccordion>
          {/* Show divider only if it's not the last item */}
          {index < 5 && <Divider sx={{ backgroundColor: "#fff" }} />}
        </Box>
      ))}
    </Box>
  );
};

export default FAQSection;
