import React, { useState } from "react";
import { Box, Typography, Chip, Button, Container } from "@mui/material";
import FeatureCard from "./FeatureCard";
import { WebData } from "../data";

const categories = ["All", "Web Design", "Branding", "Animation"];

const FeaturedWorks = () => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [visibleCount, setVisibleCount] = useState(4); // Initial items displayed

  const filteredData =
    activeCategory === "All"
      ? WebData
      : WebData.filter((item) => item.categories.includes(activeCategory));

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setVisibleCount(3); // Reset visible items on category change
  };

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 2); 
  };

  return (
    <Box
      sx={{
        width: "100%",
        mx: "auto",
        textAlign: "center",
        color: "#fff",
      }}
    >
      <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
        Featured <span style={{ color: "#ffbd59" }}>Works</span>
      </Typography>
      <Container maxWidth="xs">
        <Box
          sx={{ height: "100%", borderRadius: "50%", border: "solid 1px #fff" }}
        />
      </Container>

      <Box
        sx={{ display: "flex", gap: 1, justifyContent: "center", mb: 3, mt: 3 }}
      >
        {categories.map((category) => (
          <Chip
            key={category}
            label={category}
            color={category === activeCategory ? "primary" : "warning"}
            onClick={() => handleCategoryChange(category)}
            sx={{
              cursor: "pointer",
              color: category === activeCategory ? "#fff" : "#fff",
              backgroundColor:
                category === activeCategory
                  ? "linear-gradient(90deg, #000, #004aad)"
                  : "linear-gradient(90deg, #000, #004aad)",
              fontWeight: "bold",
              "&:hover": {
                transform: "scale(1.05)", // Slight zoom on hover
                backgroundColor:
                  category === activeCategory
                    ? "linear-gradient(90deg, #000, #004aad)"
                    : "linear-gradient(90deg, #000, #004aad)",
                color: category === activeCategory ? "#fff" : "#fff",
              },
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))", // Responsive grid layout
        }}
      >
        {filteredData.slice(0, visibleCount).map((item) => (
          <Box key={item.id}>
            <FeatureCard
              title={item.title}
              imgSrc={item.image}
              imgAlt={item.title}
              categories={item.categories}
              description={item.description}
              to={item.to}
            />
          </Box>
        ))}
      </Box>
      {visibleCount < filteredData.length && (
        <Button onClick={loadMore} variant="contained" sx={{ mt: 3 }}>
          Load More
        </Button>
      )}
    </Box>
  );
};

export default FeaturedWorks;
