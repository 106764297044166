import {Box,Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { LogosData } from "../data";


const DeltWithLogos = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          alignContent: "center",
          gap: 1,
        }}
      >
        {LogosData.map((item) => (
          <Link
            to={item.link}
            style={{ textDecoration: "none", color: "#000" }}
          >
            <Box key={item.indexOf} sx={{}}>
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: `${item.categoryColor}`,
                    top: 10,
                    right: 0,
                    px: 1,
                    py: 0.2,
                    position: "relative",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                    transform: "scale(0.9)",
                    textDecoration: "none",
                    width: "80%",
                    textAlign: "left",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0,0.4)",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    {item.category}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    component="img"
                    src={item.img}
                    width="150px"
                    sx={{
                      borderRadius: "15px",
                    }}
                  />
                </Box>
              </Box>{" "}
            </Box>
          </Link>
        ))}
      </Box>
    </>
  );
};

export default DeltWithLogos;
