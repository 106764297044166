import { Box, Container, Typography } from "@mui/material";
import React from "react";
import SkillItem from "./SkillItem";
import ReactIcon from "../assets/icons/react.png";
import MongoIcon from "../assets/icons/mongodb.png";
import NodeIcon from "../assets/icons/node.png";
import PaythonIcon from "../assets/icons/paython.png";
import VSIcon from "../assets/icons/vs.png";

const data = [
  {
    id: 1,
    img: ReactIcon,
    title: "React",
    percentage: "90%",
  },
  {
    id: 2,
    img: MongoIcon,
    title: "MongoDB",
    percentage: "90%",
  },
  {
    id: 3,
    img: NodeIcon,
    title: "NodeJS",
    percentage: "90%",
  },
  {
    id: 4,
    img: PaythonIcon,
    title: "Python",
    percentage: "90%",
  },
  {
    id: 5,
    img: VSIcon,
    title: "VsCode",
    percentage: "90%",
  },
];

const SkillsBar = () => {
  return (
    <Box
      sx={{
        width: "100%",
        mx: "auto",
        textAlign: "center",
        color: "#fff",
        mt: 10,
        py: 5,
      }}
    >
      <Typography variant="h4" sx={{ mb: 4, fontWeight: "bold" }}>
        My <span style={{ color: "#ff6347" }}>Skills</span>
      </Typography>
        <Container maxWidth="xs">
        <Box
          sx={{ height: "100%", borderRadius: "50%", border: "solid 1px #fff" }}
        />
      </Container>
      {/* Skill Items */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))", // Flexible grid for responsiveness
          gap: 2, // Gap between skill items
          justifyItems: "center", // Centers the items in each grid cell
          mt: 4,
        }}
      >
        {data.map((item) => (
          <SkillItem
            key={item.id}
            title={item.title}
            img={item.img}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SkillsBar;
