import { Box, ThemeProvider } from "@mui/material";
import React, { useState } from "react";
import { darkTheme } from "./theme";
import { Route, Routes } from "react-router-dom";
import Landing from "./Pages/Landing/Landing";
import Header from "./componens/Navbar/Header";
import hawk from "./assets/hawk.mp4";

const App = () => {
  const [theme, setTheme] = useState(darkTheme);
  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: theme.palette.background.main }}>
       <Box
        component={"video"}
        src={hawk}
        sx={{ width: "100%", display: "flex", position: "fixed", zIndex: 0 }}
        autoPlay
        muted
        loop
      />
        <Header onThemeChange={handleThemeChange} theme={theme} />
        <Routes>
          <Route path="/" exact element={<Landing />} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
};

export default App;
