import { Box, Paper, Typography, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
    subject: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "hawkzone", // Replace with your Email.js service ID
        "template_xnb77ol", // Replace with your Email.js template ID
        formData,
        "-vug0U_6_775nV3Ml" // Replace with your Email.js user/public key
      )
      .then(
        (response) => {
          console.log("Email sent successfully:", response.status, response.text);
          setStatus("Email sent successfully!");
        },
        (error) => {
          console.error("Error sending email:", error);
          setStatus("Failed to send email. Please try again.");
        }
      );
    setFormData({ name: "", email: "", message: "", phone: "", subject: "" });
  };

  return (
    <Box sx={{ bgcolor: "#151515", color: "#fff", p: 3 }}>
      <Paper
        elevation={3}
        sx={{
          padding: { md: 8, xs: 4 },
          bgcolor: "#151515",
          borderRadius: 2,
        }}
      >
        <Box sx={{ display: "flex", flexWrap: { md: "nowrap", xs: "wrap" }, mb: 5 }}>
          <Box sx={{ pr: { md: 15 }, mb: { xs: 3, md: 0 } }}>
            <Typography
              sx={{
                fontSize: { md: "65px", sm: "35px", xs: "35px" },
                fontWeight: 600,
                fontFamily: "fantasy",
                letterSpacing: { md: "-2px" },
                lineHeight: "1.1em",
              }}
            >
              Success is a team play, right?
            </Typography>
            <Typography
              sx={{
                my: 2,
                fontSize: { md: "50px", xs: "35px" },
                fontWeight: 600,
                fontFamily: "serif",
                fontStyle: "italic",
                color: "#FF8C00",
                letterSpacing: "-1px",
              }}
            >
              Let’s work together!
            </Typography>
            <Typography
              sx={{
                mb: 3,
                fontSize: { md: "20px", xs: "20px" },
                fontWeight: 600,
                fontFamily: "serif",
              }}
            >
              We will ask the right questions, discuss possibilities, and make an
              action plan.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 3 }}>
              <TextField
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                placeholder="Full Name"
                sx={textFieldStyles}
              />
              <TextField
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                placeholder="Email Address"
                sx={textFieldStyles}
              />
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 3 }}>
              <TextField
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                placeholder="Phone Number"
                sx={textFieldStyles}
              />
              <TextField
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                placeholder="Enter your subject"
                sx={textFieldStyles}
              />
            </Box>
            <TextField
              name="message"
              value={formData.message}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              placeholder="Write your message here"
              sx={textFieldStyles}
            />
            <Button
              variant="contained"
              fullWidth
              type="submit"
              sx={{
                bgcolor: "#FF8C00",
                color: "#fff",
                fontSize: "16px",
                padding: "10px 0",
                fontWeight: 600,
                borderRadius: "18px",
                "&:hover": { bgcolor: "#FF8C00" },
                mt: 3,
              }}
            >
              Send Message
            </Button>
          </form>
        </Box>
        {status && (
          <Typography
            sx={{
              mt: 2,
              textAlign: "center",
              color: status.includes("success") ? "#4caf50" : "#f44336",
            }}
          >
            {status}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

const textFieldStyles = {
  bgcolor: "transparent",
  "& .MuiOutlinedInput-root": {
    borderRadius: "18px",
    color: "#fff",
    "& fieldset": {
      borderColor: "#fff",
    },
    "&:hover fieldset": {
      borderColor: "#FF8C00",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF8C00",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#fff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#FF8C00",
  },
};

export default ContactForm;
