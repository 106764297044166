import { Avatar, Box, Container, Paper, Typography } from "@mui/material";
import React from "react";
import HeroTitle from "./HeroTitle";
import PercentComponent from "./PercentComponent";
import LogoSlider from "../../componens/LogoSlider";
import FAQSection from "../../componens/FAQSection";
import FeaturedWorks from "../../componens/FeaturedWorks";
import SkillsBar from "../../componens/SkillsBar";
import DeltWithLogos from "../../componens/DeltWithLogos";
import HawkLogo from "../../assets/HawkZone.png";
import ContactForm from "../../componens/ContactForm";

const Landing = () => {
  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <Box
          component={"img"}
          src={HawkLogo}
          sx={{ width: { xl: "150px", xs: "100px" } }}
        />
      </Box>
      <Container
        sx={{
          mt: { md: 15, sm: 15, xs: 2 },
          maxWidth: { xl: "xl", lg: "lg", md: "md", sm: "sm", xs: "xs" },
          position: "relative",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ my: 2, ml: { xs: 2 } }}>
            <HeroTitle
              subtitle="Design."
              title="Development."
              main="Mastership"
            />
          </Box>
          <Box
            sx={{
              gap: 4,
              display: "flex",
              flexDirection: { md: "column" },
              my: 5,
            }}
          >
            <PercentComponent
              number="95"
              sign="%"
              before="Clients satisfied and"
              after="repeating"
            />
            <PercentComponent
              number="18"
              sign="+"
              before="Companies Delt with"
              after="till now"
            />
          </Box>
        </Box>
        <Box sx={{ pb: 5 }}>
          <Box
            sx={{
              pt: { md: 20, xs: 25 },
              mx: { xs: 2 },
              display: "flex",
              justifyContent: "flex-start",
              width: { md: "400px" },
            }}
          >
            <Typography
              sx={{ fontFamily: "sans-serif", fontSize: { md: "18px" } }}
            >
              Amplifying Creativity: From Brand Design to Digital Strategy.
            </Typography>
          </Box>
        </Box>

        {/* About Section */}
        <Box sx={{ py: 10 }}>
          <Paper
            elevation={7}
            sx={{
              backgroundColor: "#151515",
              p: { md: 10, xs: 2 },
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box sx={{ display: "flex", gap: 2, pt: { xs: 2 } }}>
                <Typography variant="h2"> About</Typography>
                <Typography variant="h2" color="darkOrange">
                  {" "}
                  Me
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
                    Empowering Sustainable Growth: Tailored Software,
                    Cutting-Edge Products, and Expert Consulting.
                  </Typography>
                  <Typography sx={{ fontSize: { md: "28px", xs: "14px" } }}>
                    I specialize in full-stack web development to enhance
                    business growth, focusing on custom web solutions with
                    robust backend architecture and responsive front-end design.
                    I employ the latest technologies for scalable, secure,
                    high-performance websites, improving functionality, speed,
                    and user experience. I provide expert consulting from
                    concept to deployment for lasting success.
                  </Typography>
                </Box>

                <Box sx={{ display: { md: "none", sm: "flex" } }}>
                  <LogoSlider />
                </Box>
              </Box>
              <Box sx={{ display: { md: "flex", xs: "none", mt: 5 } }}>
                <DeltWithLogos />
              </Box>
            </Box>
          </Paper>
        </Box>
        {/*FeaturedWorks */}
        <FeaturedWorks />

        {/* Our Skills */}
        <SkillsBar />
        {/*FAQ Section */}
        <Box sx={{ py: { md: 5 }, my: { xs: 2 } }}>
          <Paper
            elevation={7}
            sx={{
              backgroundColor: "#151515",
              p: { md: 5, xs: 2 },
              height: { xl: "100%", md: "100%", xs: "80vh" },
            }}
          >
            <FAQSection />
          </Paper>
        </Box>

        <ContactForm />
        {/* Footer */}
        <Container maxWidth="xl" sx={{ height: "100%", mt: 10 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              justifyItems: "center",
              alignContent: "center",
              alignItems: "center",
              flexWrap: { xs: "wrap" },
              gap: 2,
            }}
          >
            <Box>
              <Box component={"img"} src={HawkLogo} sx={{ width: "30%" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "left",
              }}
            >
              <Typography>Location</Typography>
              <Typography>Cairo,Egypt</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "left",
                alignContent: "center",
                justifyItems: "center",
              }}
            >
              <Typography>Inquiry</Typography>
              <Typography>(+02) 011 454 29 440</Typography>
              <Typography>info@mazenaldaher.com</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}></Box>
          </Box>
        </Container>
        <Box
          sx={{
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            justifyItems: "center",
            py: 5,
          }}
        >
          <Typography sx={{ textAlign: "right" }}>
            © 2024 All Copyright and Right Reserved.
          </Typography>

          <Box component={"img"} src={HawkLogo} sx={{ width: "10%" }} />
        </Box>
      </Container>
    </Box>
  );
};

export default Landing;
