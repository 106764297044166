import ACI from "./assets/companies/ACI.png";
import POWER from "./assets/companies/power_cord.png";
import SIWA from "./assets/companies/siwa.png";
import OROBA from "./assets/companies/oroba.png";
import ATIC from "./assets/companies/atic.png";
import AABHGT from "./assets/companies/aabhgt.png";
import ABHRT from "./assets/companies/abhrt.png";
import ABHI from "./assets/companies/abhi.png";
import ELEVA_COFFEE from "./assets/companies/eleva_coffee.png";
import ELEVA_LOUNGE from "./assets/companies/eleva_lounge.png";
import HG from "./assets/companies/hg.png";
import CEDG from "./assets/companies/cedg.png";
import GKT from "./assets/companies/gkt.png";
import SUN from "./assets/companies/sunoil.png";
import HGM from "./assets/companies/hgm.png";
import BLUE from "./assets/companies/blue_gold.png";
import ST from "./assets/companies/silat_academy.png";
import ECPS from "./assets/companies/ECPS.png";
import ElevaApp from "./assets/webapp/eleva_coffee.png";
import ELOUNGE from "./assets/webapp/E_LOUNGE.png";
import HGLOBAL from "./assets/webapp/hgm.png";
import HGWP from "./assets/webapp/hg.png";
import POWERWP from "./assets/webapp/powercord.png";
import TEDDY from "./assets/webapp/teddy.png";
import FAISAL from "./assets/webapp/ahmed-faisal.png";

export const LogosData = [
  {
    id: 10,
    link: "",
    img: BLUE,
    category: "GT",
    categoryColor: "darkOrange",
  },
  {
    id: 10,
    link: "",
    img: ST,
    category: "sport",
    categoryColor: "orange",
  },
  {
    id: 10,
    link: "",
    img: HGM,
    category: "oil&gas",
    categoryColor: "darkOrange",
  },

  {
    id: 10,
    link: "",
    img: SUN,
    category: "oil&gas",
    categoryColor: "darkOrange",
  },
  {
    id: 10,
    link: "",
    img: GKT,
    category: "oil&gas",
    categoryColor: "darkOrange",
  },
  {
    id: 9,
    link: "",
    img: ELEVA_COFFEE,
    category: "Coffee",
    categoryColor: "darkOrange",
  },
  {
    id: 10,
    link: "",
    img: CEDG,
    category: "oil&gas",
    categoryColor: "darkOrange",
  },

  {
    id: 10,
    link: "",
    img: ELEVA_LOUNGE,
    category: "coffee",
    categoryColor: "darkOrange",
  },
  {
    id: 10,
    link: "",
    img: HG,
    category: "events",
    categoryColor: "darkOrange",
  },
  {
    id: 10,
    link: "",
    img: ECPS,
    category: "sport",
    categoryColor: "orange",
  },

  {
    id: 1,
    link: "",
    img: ACI,
    category: "ACI",
    categoryColor: "darkOrange",
  },
  {
    id: 2,
    link: "",
    img: POWER,
    category: "Electrical",
    categoryColor: "darkOrange",
  },
  {
    id: 3,
    link: "",
    img: SIWA,
    category: "factory",
    categoryColor: "darkOrange",
  },
  {
    id: 4,
    link: "",
    img: OROBA,
    category: "real estate",
    categoryColor: "darkOrange",
  },
  {
    id: 5,
    link: "",
    img: ATIC,
    category: "ATIC",
    categoryColor: "darkOrange",
  },
  {
    id: 6,
    link: "",
    img: AABHGT,
    category: "GT",
    categoryColor: "darkOrange",
  },
  {
    id: 7,
    link: "",
    img: ABHRT,
    category: "real estate",
    categoryColor: "darkOrange",
  },
  {
    id: 8,
    link: "",
    img: ABHI,
    category: "investments",
    categoryColor: "darkOrange",
  },
];

export const WebData = [
  {
    id: 3,
    year: 2024,
    title: "Heard Group ",
    description: "",
    categories: ["Web Design"],
    image: HGWP,
    to: "https://heard-group.net",
  },
  {
    id: 1,
    year: 2024,
    title: "AHMED FAISAL - LANDING",
    description: "",
    categories: ["Web Design"],
    to: "https://ahmed-faisal.net",
    image: FAISAL,
  },
  {
    id: 1,
    year: 2024,
    title: "ELEVA COFFEE",
    description: "",
    categories: ["Web Design"],
    to: "https://elevacoffee.com",
    image: ElevaApp,
  },
  {
    id: 5,
    year: 2024,
    title: "POWER CORD EGYPT",
    description: "",
    categories: ["Web Design"],
    image: POWERWP,
    to: "https://powercordegypt.com",
  },

  {
    id: 2,
    year: 2024,
    title: "ELEVA LOUNGE ",
    description: "",
    categories: ["Web Design"],
    image: ELOUNGE,
    to: "https://elevacoffeelounge.com",
  },

  {
    id: 4,
    year: 2024,
    title: "HEARD GLOBAL MENA",
    description: "",
    categories: ["Web Design"],
    image: HGLOBAL,
    to: "https://heardglobalmena.com",
  },
  
  {
    id: 5,
    year: 2024,
    title: "TEDDY DOUNTS",
    description: "",
    categories: ["Web Design"],
    image: TEDDY,
    to: "",
  },
];
