import {
  AppBar,
  Box,
  Button,
  Container,
  Fab,
  IconButton,
  List,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ThemeToggleBar from "../../Utils/ThemeToggleBar";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HomeIcon from "@mui/icons-material/Home";
import StoreIcon from "@mui/icons-material/Store";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import HawkLogo from "../../assets/HawkZone.png";
const navItems = [
  { title: "About Us", link: "/about-us", icon: <HomeIcon /> },
  { title: "Store", link: "/products", icon: <StoreIcon /> },
  { title: "Community", link: "/our-community", icon: <DynamicFeedIcon /> },
  { title: "Support", link: "/support", icon: <SupportAgentIcon /> },
  { title: "FAQ", link: "/faq", icon: <ContactSupportIcon /> },
];

const Header = ({ onThemeChange, theme }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = () => {
    const currentScroll = window.scrollY;
    setShowBackToTop(currentScroll > 300);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderNavButtons = useMemo(
    () =>
      navItems.map((item) => (
        <Box sx={{ display: "flex", ml: 1 }}>
          <Button
            startIcon={item.icon}
            key={item.link}
            color="inherit"
            onClick={() => navigate(item.link)}
            sx={{
              color:
                location.pathname === item.link
                  ? theme.palette.warning.main
                  : "inherit",
              textAlign: "left",
            }}
          >
            <Typography variant="navtext">{item.title}</Typography>
          </Button>
        </Box>
      )),
    [location.pathname, theme.palette.warning.main, navigate]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Box sx={{ backgroundColor: "transparent" }}>
        <Box sx={{ px: { xl: 20, xs: 1 } }}>
          <Toolbar
            sx={{ display: "flex", justifyContent: "space-between", py: 1 }}
          >
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={HawkLogo}
                  sx={{ width: "50%" }}
                />
              </Box>
            </Link>
            <Box
              sx={{
                display: {
                  xl: "flex",
                  md: "flex",
                  sm: "none",
                  xs: "none",
                  flexDirection: "column",
                },
              }}
            >
              <Typography variant="body1">+20 114 542 9440</Typography>
              <Typography variant="body1">info@mazenaldaher.com</Typography>
            </Box>
            <Box sx={{ display: { md: "flex", xs: "none" }, gap: 5 }}>
              <Button variant="contained" sx={{ textTransform: "uppercase" }}>
                Contact
              </Button>
              {/*
              <ThemeToggleBar onThemeChange={onThemeChange} />
               */}
            </Box>
            <Box sx={{ display: "none" }}>
              <Fab
                color="primary"
                onClick={handleDrawerToggle}
                sx={{
                  top: "100%",
                  left: "16px",
                  scale: 0.8,
                }}
              >
                <MenuIcon />
              </Fab>
            </Box>
          </Toolbar>
        </Box>
      </Box>
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            position: "absolute",
            bottom: 0,
            width: { md: "15%", sm: "25%", xs: "40%" },
            height: "30vh",
            borderEndEndRadius: "20px",
            borderStartEndRadius: "20px",
            border: "none",
            padding: 0,
            pt: 2,
          },
        }}
      >
        <List>{renderNavButtons}</List>
      </SwipeableDrawer>
      {showBackToTop && (
        <IconButton
          onClick={scrollToTop}
          sx={{ position: "fixed", bottom: 16, right: 16, zIndex: 1000 }}
        >
          <ArrowUpwardIcon />
        </IconButton>
      )}
    </>
  );
};

export default Header;
