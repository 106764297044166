import { Box, Typography } from "@mui/material";
import React from "react";

const HeroTitle = ({subtitle,title,main}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="Heading">{subtitle}</Typography>
      <Typography variant="Heading">{title}</Typography>
      <Typography
        variant="Heading"
        sx={{
          fontFamily: "serif",
          fontStyle: "italic",
          color: "#ffbd59",
        }}
      >
        {main}
      </Typography>
    </Box>
  );
};

export default HeroTitle;
