/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { LogosData } from "../data";


const LogoSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideInterval = 3000;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? LogosData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === LogosData.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(handleNext, slideInterval);
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [currentIndex]);

  return (
    <Box sx={{ textAlign: "center", py: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
 
        }}
      >
        {/* Slider Container */}
        <Box
          sx={{
            display: "flex",
            overflow: "hidden",
            width: { sm: "100%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              transform: `translateX(-${currentIndex * (200 / LogosData.length)}%)`,
              transition: "transform 4s",
              opacity: 1,
              gap: 1,
            }}
          >
            {LogosData.map((item) => (
              <Box
                key={item.id}
                sx={{
                  minWidth: "20%", // Show 3 logos at a time
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 1,
                  backgroundColor: "#fff",
                  borderRadius: "15px",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: `${item.categoryColor}`,
                    top: 0,
                    right: 0,
                    px: 1,
                    py: 1,
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                    transform: "scale(0.9)",
                    textDecoration: "none",
                    width: "100%",
                    textAlign: "left",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0,0.4)",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    {item.category}
                  </Typography>
                </Box>
                <Link to={item.to}>
                  <img
                    src={item.img}
                    alt={`Logo ${item.id}`}
                    style={{
                      maxWidth: "80%",
                      
                    }}
                  />
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LogoSlider;
